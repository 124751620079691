import { faChevronDown, faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

type ButtonProps = {
	selected?: boolean;
};

export enum FilterKeys {
	BRANDING = 'branding',
	CLIENTS = 'clients',
	COPYWRITING = 'copywriting',
	CUSTOMER_SERVICE = 'customer-service',
	DESIGN = 'design',
	FINANCE = 'finance',
	GROWTH = 'growth',
	HR = 'hr',
	LEGAL = 'legal',
	MARKETING = 'marketing',
	OPERATIONS = 'operations',
	PERSONAL_DEVELOPMENT = 'personal-development',
	PRODUCT = 'product',
	PRODUCTIVITY = 'productivity',
	SALES = 'sales',
	SOFTWARE = 'software',
	STARTUP = 'startup',
	STARTUP_CLUB = 'startupclub',
	TECH = 'tech',
	WED_DESIGN = 'web-design',
}

type Filter = {
	key: FilterKeys;
	title: string;
	to: string;
};

type Props = {
	selected?: FilterKeys;
	onSelect?: (key: FilterKeys) => void;
	selectedKey?: FilterKeys;
};

const Container = styled.div`
	width: 100%;
	display: flex;
	font-family: ${(props) => props.theme.font.primary};
	font-size: ${(props) => props.theme.fontSize.body};
	align-items: center;

	padding: ${({ theme }) => theme.spacing[4]};
	padding-left: ${(props) => props.theme.spacing[10]};
	padding-right: ${(props) => props.theme.spacing[10]};
	margin-bottom: ${({ theme }) => theme.spacing[16]};
	box-shadow: 2px 2px 14px 2px rgba(0, 0, 0, 0.15);
	background: ${({ theme }) => theme.colors.white};
	border-radius: ${({ theme }) => theme.borderRadius.card};
`;

const OptionsContainer = styled.div`
	display: flex;

	width: 100%;

	flex-wrap: wrap;
	align-items: center;
	a {
		color: ${(props) => props.theme.colors.text};
		text-decoration: none;

		line-height: unset;
	}
	@media (max-width: 767px) {
		justify-content: space-around;
		/* a {
			border: ${(props) => `2px solid ${props.theme.colors.grey}`};
			color: ${(props) => props.theme.colors.text};
			text-decoration: none;
			margin: 10px;
			padding: ${(props) => props.theme.spacing[2]};
		} */
	}
`;

const StyledLink = styled(Link)<ButtonProps>`
	margin-top: ${(props) => props.theme.spacing[1]};
	margin-right: ${(props) => props.theme.spacing[8]};
	background-color: transparent;
	background: ${({ theme, selected }) => theme.colors.white};
	text-decoration: ${(props) => props.selected && 'underline'};
	border-bottom: ${(props) =>
		props.selected && `2px solid ${props.theme.colors.primaryDark}`};
	font-weight: ${(props) => props.selected && 500};
	text-transform: capitalize;
`;

const FilterLeft = styled.div`
	flex: 1;

	min-width: 130px;

	svg {
		color: ${(props) => props.theme.colors.primary};
		margin-right: ${(props) => props.theme.spacing[4]};
	}
	@media (max-width: 767px) {
		display: none;
	}
`;

const Select = styled.select`
	line-height: unset;
	text-transform: capitalize;

	border: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
`;

const OtherContainer = styled.span`
	align-items: center;
	text-transform: capitalize;

	svg {
		margin-left: ${(props) => props.theme.spacing[4]};
	}
`;

const MAIN_LIMIT = 8;

const ArticlesFilter = (props: Props) => {
	const result =
		useStaticQuery<GatsbyTypes.GetStartUpClubArticleCategoriesQuery>(graphql`
			query GetStartUpClubArticleCategories {
				allStartupClubArticles {
					group(field: interests___slug) {
						field
						fieldValue
					}
				}
			}
		`);
	const groups = result.allStartupClubArticles.group.map(
		(group) => group.fieldValue
	);

	const availableFilters = groups.map((filterGroup) => {
		if (filterGroup === 'startupclub') {
			return { key: filterGroup, title: 'Startup Club' };
		}

		return {
			key: filterGroup,
			title: filterGroup?.replace('-', ' '),
		};
	});

	const onSelectOtherOption = (value: string) => {
		// console.log(`value`, value);
		navigate(`/clubs/articles/${value}`);
	};

	return (
		<Container id="filter-articles-header">
			<FilterLeft>
				<FontAwesomeIcon icon={faFilter} />
				Filter articles:
			</FilterLeft>
			<OptionsContainer>
				{availableFilters.slice(0, MAIN_LIMIT).map((filter) => {
					return (
						<StyledLink
							to={`/clubs/articles/${filter.key}`}
							selected={
								props.selected === filter.key ||
								props.selectedKey === filter.key
							}
							key={`${filter.key}`}
						>
							{filter.title}
						</StyledLink>
					);
				})}

				{availableFilters.length > MAIN_LIMIT && (
					<OtherContainer>
						<Select
							name="more options"
							onChange={(e) => onSelectOtherOption(e.target.value)}
						>
							<option>Other Interests</option>;
							{availableFilters.slice(MAIN_LIMIT, 200).map((filter) => {
								return (
									<option key={filter.key} value={filter.key}>
										{filter.title}
									</option>
								);
							})}
						</Select>
						<FontAwesomeIcon icon={faChevronDown} />
					</OtherContainer>
				)}
			</OptionsContainer>
		</Container>
	);
};

export default ArticlesFilter;
