import { graphql, Link } from 'gatsby';
import React, { Fragment, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { ListArticlesAndClubsQuery } from '../../graphql-types';
import {
	CentralTextBlock,
	Context,
	Hero,
	MetaData,
	NewsCard,
	TitleSection,
} from '../components';
import ArticlesFilter from '../components/ArticlesFilter';
import { FilterKeys } from '../components/ArticlesFilter/ArticlesFilter';
import Button from '../components/Button';
import ContentCard from '../components/ContentCard';
import ThreeCol from '../components/ThreeCol';
import useStartupClubEvents from '../utils/hooks/useStartupClubEvents';

type Props = {
	data: ListArticlesAndClubsQuery;
};

const Clubs = ({ data }: Props) => {
	const [filterKey, setFilterKey] = useState<FilterKeys>();

	const { futureEvents } = useStartupClubEvents();

	let articles = data?.articles?.nodes ?? [];

	if (filterKey) {
		articles = articles.filter((art) => {
			const interests = art?.interests?.map?.((inter) => inter?.slug);
			return interests?.includes?.(filterKey);
		});
	}

	const acf = data.clubs?.acf;

	return (
		<Context.Consumer>
			{({ setModal }) => {
				return (
					<Fragment>
						<MetaData
							title={acf?.meta_title ?? data.clubs?.title ?? ''}
							description={acf?.meta_description ?? ''}
						/>

						<Hero
							images={acf?.clubs_header_images}
							title={acf?.clubs_header_title}
							logo={acf?.clubs_header_logo && acf?.clubs_header_logo.source_url}
							link={acf?.clubs_header_link}
							linkText={acf?.clubs_header_link_text}
							linkPopup={acf?.clubs_header_link_popup}
						/>

						{acf?.grey_box?.link_text && (
							<CentralTextBlock
								title={acf?.grey_box.title}
								text={acf?.grey_box.text}
								linkPopup={acf?.grey_box.link_popup}
								link={acf?.grey_box.link}
								linkText={acf?.grey_box.link_text}
								after={
									<div className="button-wrapper-5-to-9">
										{acf.grey_box.link_popup ? (
											<Button onClick={() => setModal('Find out more')}>
												{acf.grey_box.link_text}
											</Button>
										) : acf?.grey_box?.link?.slice?.(0, 4) === 'http' ? (
											<Button href={acf?.grey_box.link ?? ''} target={'_blank'}>
												{acf.grey_box.link_text}
											</Button>
										) : (
											<Link
												to={acf?.grey_box.link ?? ''}
												className="w-button w-button--inverted"
											>
												{acf.grey_box.link_text}
											</Link>
										)}
									</div>
								}
							/>
						)}
						{/* 
						{acf.clubs_content && !!acf.clubs_content.length && (
							<TitleSection
								title={acf.clubs_content_title}
								content={
									<div className="card-row w-row">
										{acf.clubs_content.map(({ title, link, image }) => (
											<Fade key={`club-${title}`}>
												<div className="al-row--col1 w-col w-col-4">
													<ColorBlock
														title={title}
														// text={club.title}
														image={image && image.source_url}
														color={true}
														url={link}
													/>
												</div>
											</Fade>
										))}
									</div>
								}
							/>
						)} */}

						{!!futureEvents.length && (
							<TitleSection
								title={acf?.events_title ?? ''}
								// color={"grey"}
								content={
									<ThreeCol>
										{futureEvents.slice(0, 3).map((event) => {
											return (
												<Fade key={event.node.title}>
													<NewsCard
														title={event.node.title}
														text={event.node.excerpt}
														image={event?.node?.acf?.image?.source_url}
														link={`/event/${event.node.slug}`}
													/>
												</Fade>
											);
										})}
									</ThreeCol>
								}
							/>
						)}

						<TitleSection
							title={''}
							content={
								<>
									<ArticlesFilter
										onSelect={setFilterKey}
										selected={filterKey}
									/>
									<ThreeCol>
										{articles.map((article) => {
											return (
												<ContentCard
													readTime={article.read_time ?? 0}
													date={article.date}
													link={`/articles/${article?.slug}` ?? ''}
													text={article?.excerpt ?? ''}
													image={article?.images?.banner ?? ''}
													title={article?.title ?? ''}
												/>
											);
										})}
									</ThreeCol>
								</>
							}
						/>
					</Fragment>
				);
			}}
		</Context.Consumer>
	);
};

// export const query = graphql`
// 	query ListFilteredArticles($slug: String) {
// 		allArticles(filter: { interests: { elemMatch: { slug: { eq: $slug } } } }) {
// 			nodes {
// 				id
// 				title
// 			}
// 		}
// 	}
// `;
export const query = graphql`
	query ListArticlesAndClubs {
		articles: allStartupClubArticles(
			filter: { slug: { ne: "null" }, read_time: { gt: 0 } }
			sort: { order: DESC, fields: date }
			limit: 10
		) {
			nodes {
				title
				images {
					banner
				}
				excerpt
				date(fromNow: true)
				read_time
				slug
				interests {
					slug
				}
			}
		}
		clubs: wordpressPage(slug: { eq: "clubs" }) {
			title
			acf {
				clubs_header_title
				clubs_header_images {
					image {
						source_url
					}
					focus
				}
				clubs_header_logo {
					source_url
				}
				clubs_header_link_popup
				clubs_header_link
				clubs_header_link_text
				grey_box {
					title
					text
					link
					link_text
					link_popup
				}
				events_title
				clubs_content_title
				clubs_content {
					title
					link
					image {
						source_url
					}
				}
				meta_title
				meta_description
			}
		}
	}
`;
export default Clubs;
