import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import dayjs from 'dayjs';

type Event = GatsbyTypes.UpcomingStartupClubsQuery['events']['edges'][0];

const useSpaces = () => {
	const { events } =
		useStaticQuery<GatsbyTypes.UpcomingStartupClubsQuery>(graphql`
			query UpcomingStartupClubs {
				events: allWordpressWpEvent(filter: { event_category: { eq: 12 } }) {
					edges {
						node {
							title
							slug
							excerpt
							wordpress_id
							event_category
							acf {
								date
								start_time
								end_time
								booking_link
								meta_title
								meta_description
								image {
									source_url
									alt_text
								}
							}
						}
					}
				}
			}
		`);

	const findOne = ({
		slug,
		wordpress_id,
	}: {
		slug?: string;
		wordpress_id?: number;
	}): Event | undefined => {
		return events.edges.find(({ node }) => {
			if (slug) return node.slug === slug;
			if (wordpress_id) return node.wordpress_id === wordpress_id;
			return null;
		});
	};

	return {
		allEvents: events.edges,
		futureEvents: events.edges.filter((event) =>
			dayjs().isBefore(dayjs(event.node.acf?.date))
		),
		findOne,
	} as const;
};

export default useSpaces;
